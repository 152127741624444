<template>
  <v-row no-gutters>
    <v-col>
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            :value="formatValue()"
            clearable
            :label="$t('filters.purchaseFilter.startDate')"
            :placeholder="$t('filters.purchaseFilter.startDate')"
            prepend-inner-icon="mdi-calendar"
            solo-inverted
            flat
            hide-details
            v-bind="attrs"
            v-on="on"
            @click:clear="pagination.startDate = null"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="pagination.startDate"
          :placeholder="$t('filters.purchaseFilter.startDate')"
          :label="$t('filters.purchaseFilter.startDate')"
          @change="saveStart"
        ></v-date-picker>
      </v-menu>
    </v-col>
    <v-col class="mx-2">
      <v-menu
        ref="menu2"
        v-model="menu2"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            :value="formatValue2()"
            clearable
            :label="$t('filters.purchaseFilter.endDate')"
            :placeholder="$t('filters.purchaseFilter.endDate')"
            prepend-inner-icon="mdi-calendar"
            solo-inverted
            flat
            hide-details
            v-bind="attrs"
            v-on="on"
            @click:clear="pagination.endDate = null"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="pagination.endDate"
          :placeholder="$t('filters.purchaseFilter.endDate')"
          :label="$t('filters.purchaseFilter.endDate')"
          @change="saveEnd"
        ></v-date-picker>
      </v-menu>
    </v-col>
    <v-col>
      <v-autocomplete
        v-model="pagination.state"
        :items="purchaseState"
        class="mx-4"
        flat
        hide-details
        @change="pagination.skip = 0"
        :label="$t('filters.purchaseFilter.filterLabel')"
        solo-inverted
      ></v-autocomplete>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "PurchaseFilter",
  computed: {
    pagination: {
      get() {
        return this.$store.getters["purchases/pagination"];
      },
      set(value) {
        this.$store.dispatch("purchases/setPagination", value);
      }
    }
  },

  methods: {
    saveStart(date) {
      this.$refs.menu.save(date);
    },
    saveEnd(date) {
      this.$refs.menu2.save(date);
    },
    formatValue() {
      return this.formatDate(this.pagination.startDate, "YYYY-MM-DD");
    },
    formatValue2() {
      return this.formatDate(this.pagination.endDate, "YYYY-MM-DD");
    }
  },
  data() {
    return {
      menu: false,
      menu2: false,
      purchaseState: [
        { value: null, text: this.$t("filters.purchaseFilter.all") },
        { value: 0, text: this.$t("filters.purchaseFilter.WaitingForApproval") },
        { value: 1, text: this.$t("filters.purchaseFilter.Approved") },
        { value: 2, text: this.$t("filters.purchaseFilter.Delivered") },
        { value: 3, text: this.$t("filters.purchaseFilter.Canceled") }
      ]
    };
  }
};
</script>
